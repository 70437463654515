<!--
 * @Description: 待确认
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-15 20:27:09
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/release/mods/noConfirm/index.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="公司名称" prop="search">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="530"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="客户名称"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="package_type"
        show-overflow-tooltip
        label="套餐类型"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ai_version"
        show-overflow-tooltip
        label="视频类型"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="各环节是否已确认"
        align="center"
      >
        <template slot-scope="{ row }">
          {{
            row.contract_pay === 1 &&
            row.seed_keywords_approved === 2 &&
            row.frequet_keywords_extraction === 2 &&
            row.promotion_content_creation === 2
              ? "是"
              : "否"
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="demo_video_creation_at"
        show-overflow-tooltip
        label="生成预览视频时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="opts" label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <template
            v-if="row.demo_video_creation === 2 && row.demo_video_file !== null"
          >
            <el-button
              size="mini"
              type="text"
              @click="handleOpts('preview', row)"
              >预览视频</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleOpts('release', row)"
              >确认发布</el-button
            >
          </template>
          <template
            v-else-if="
              row.contract_pay === 1 &&
              row.seed_keywords_approved === 2 &&
              row.frequet_keywords_extraction === 2 &&
              row.promotion_content_creation === 2
            "
          >
            <el-button
              size="mini"
              type="text"
              @click="handleOpts('generate', row)"
              >生成视频</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";
import VideoView from "@/pages/views/custom/examine/components/video.vue";

const URL = {
    list: "api/operation/release/list",
    createVideo: "api/operation/release/createVideo",
    confirm: "api/operation/release/confirm",
  },
  QUERY = {
    company: "",
  };
export default {
  components: {
    VideoView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const params = {
        company: this.queryParams.company,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          video_creation: 0,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },

    async handleConfirm(id) {
      try {
        await HTTP({
          url: URL.confirm,
          method: "post",
          data: {
            id,
          },
        });
        this.$message.success("操作成功");
        this.getList();
      } catch (e) {
        console.log(e);
      }
    },

    handleRelease({ id }) {
      this.$confirm("请确认视频生产无问题并发布？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleConfirm(id);
        })
        .catch(() => {});
    },

    async handleGenerate({ id }) {
      try {
        await HTTP({
          url: URL.createVideo,
          method: "post",
          data: {
            id,
          },
        });
        this.$message.success("操作成功");
        this.getList();
      } catch (e) {
        console.log(e);
      }
    },

    handleVideoChange({ demo_video_file_path }) {
      this.video = {
        show: true,
        data: {
          content: demo_video_file_path,
        },
      };
    },

    handleOpts(type, row) {
      switch (type) {
        case "release":
          this.handleRelease(row);
          break;
        case "generate":
          this.handleGenerate(row);
          break;
        case "preview":
          this.handleVideoChange(row);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  cursor: pointer;
}
</style>
