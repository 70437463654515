<!--
 * @Description: 已发布
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-15 20:28:31
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/release/mods/published/index.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="公司名称" prop="search">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="530"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="客户名称"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="package_type"
        show-overflow-tooltip
        label="套餐类型"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ai_version"
        show-overflow-tooltip
        label="视频类型"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="video_publish_last_dt"
        show-overflow-tooltip
        label="确认发布时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="opts" label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <el-button size="mini" type="text" @click="handleOpts('preview', row)"
            >预览视频</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import { HTTP } from "../../../../../../utils/request";
import VideoView from "@/pages/views/custom/examine/components/video.vue";

const URL = {
    list: "api/operation/release/list",
  },
  QUERY = {
    company: "",
  };
export default {
  components: {
    VideoView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const params = {
        company: this.queryParams.company,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          video_creation: 1,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },

    handleVideoChange({ demo_video_file_path }) {
      this.video = {
        show: true,
        data: {
          content: demo_video_file_path,
        },
      };
    },

    handleOpts(type, row) {
      switch (type) {
        case "preview":
          this.handleVideoChange(row);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  cursor: pointer;
}
</style>
