<!--
 * @Description: 播放视频
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-26 21:22:32
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/examine/components/video.vue
-->
<template>
  <div class="videos-popup" @click="shows = false">
    <div class="videos" @click.stop>
      <div class="videos-text">播放视频</div>
      <div class="videos-content">
        <video controls>
          <source :src="data.content" type="video/mp4" />
        </video>
      </div>
      <div class="videos-btn">
        <div class="btn" @click="shows = false">关闭</div>
      </div>
      <div class="videos-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
  .videos {
    width: 427px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      margin: auto {
        top: 25px;
      }
      width: 194px;
      height: 341px;
      background-color: #eee;
      position: relative;
      overflow: hidden;
      video {
        width: 100%;
        height: 100%;
      }
      .content-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #fff, $alpha: 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 37.14px;
          height: 37.14px;
          cursor: pointer;
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
