<!--
 * @Description: 发布管理
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-15 20:15:07
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/release/index.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">发布管理</div>
    <el-tabs v-model="activeState" style="margin-top: 20px">
      <el-tab-pane label="待确认" name="1"></el-tab-pane>
      <el-tab-pane label="已发布" name="2"></el-tab-pane>
    </el-tabs>

    <!-- 待确认 -->
    <NoConfirmView v-if="activeState === '1'" />

    <!-- 已发布 -->
    <PublishedView v-if="activeState === '2'" />
  </div>
</template>

<script>
import NoConfirmView from "./mods/noConfirm/index.vue";
import PublishedView from "./mods/published/index.vue";
export default {
  components: {
    NoConfirmView,
    PublishedView,
  },
  data() {
    return {
      activeState: "1",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
